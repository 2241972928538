<template>
  <div class="col main_logs_wrapping_container">
    <server-table
      :count="$store.getters['logs/totalLogs']"
      :DataItems="$store.getters['logs/logs']"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :action="false"
      :loading="$store.getters['logs/logsLoad']"
      class="table table-striped table-hover dataTable dtr-inline"
      :title="`${localization('Logs')}`"
      :isFilterLogs="true"
    >
      <template #columns>
        <el-table-column
          prop="id"
          label="#"
          width="100"
          sortable
        ></el-table-column>
        <el-table-column
          prop="action"
          :label="`${localization('Action')}`"
          sortable
        ></el-table-column>
        <el-table-column
          prop="first_name"
          :label="`${localization('Name')}`"
        ></el-table-column>
        <el-table-column
          prop="created_at"
          :label="`${localization('Date')}`"
          sortable
        >
          <template slot-scope="scope">
            {{ toLocalDatetime(scope.row.created_at, "en") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="model"
          :label="`${localization('Model')}`"
        ></el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <button
                v-if="hasPermission('show_logs')"
                @click="open_log_show_dialog(scope.row)"
                v-tooltip.top-center="`${localization('View log')}`"
                class="btn btn-warning btn-link btn-xs"
              >
                <i class="fa fa-history"></i>
              </button>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>

    <!-- //? View Log history  -->
    <el-dialog
      :title="`${localization('View log')}`"
      :visible.sync="log_dialog_show"
      top="4vh"
      width="80%"
      class="add_logs_dialog_user"
    >
      <el-row :gutter="10">
        <el-col :md="12">
          <h5>{{ localization("New Log") }}</h5>
          <Info v-if="new_log" :items="new_log" />
        </el-col>
        <el-col :md="12">
          <h5>{{ localization("Old Log") }}</h5>
          <Info v-if="old_log" :items="old_log" />
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="log_dialog_show = false">{{
          localization("Close")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- //? View Log history  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Info from "../../components/Cards/Info";

import { Table, TableColumn, Select, Option } from "element-ui";

export default {
  name: "index",
  components: {
    ServerTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Info
  },
  data() {
    return {
      search: null,
      filter: {},
      log_dialog_show: false,
      shown_log: null,
      old_log: {},
      new_log: {}
    };
  },

  methods: {
    Refresh(query) {
      this.$store.dispatch("logs/getLogs", { query: query });
    },
    open_log_show_dialog(data) {
      if (data.new_value && data.new_value != "null") {
        this.new_log = Object.entries(JSON.parse(data.new_value)).map(
          ([key, value]) => {
            return { key, value };
          }
        );
      }
      if (data.old_value && data.old_value != "null") {
        this.old_log =
          data.old_value &&
          Object.entries(JSON.parse(data.old_value)).map(([key, value]) => {
            return { key, value };
          });
      }
      this.log_dialog_show = true;
      console.log(data);
    }
  }
};
</script>

<style scoped>
.container--fluid {
  min-height: 80vh !important;
}
::v-deep .el-table .cell {
  /* inline-size: max-content; */
}
</style>

<style lang="scss">
// ? dialog style
.add_logs_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.main_logs_wrapping_container {
  .el-table .cell {
    word-break: break-word;
    white-space: normal;
  }
}
</style>
